<template>
  <div class="Sjzhuce registerpc">
    <div class="login_pcbox">
      <div class="login_pccenter" style="padding-bottom: 5.56rem">
        <div class="login_pccenterlogo">
          <router-link to="/"
            ><img
              src="https://task.hozoin.cn/Works/20210119/sj-1758342099.png"
              alt=""
          /></router-link>
          <router-link class="login_pccenterlogoqx" to="/">取消</router-link>
        </div>
        <div class="login_pccenterbox login_pccenterboxzhuce">
          <div class="login_pccenterboxtitle">HI,注册成为朋友~</div>
          <el-tabs v-model="first" @tab-click="changedl">
            <el-tab-pane label="设计师注册" name="first">
              <div class="login_pccenterboxmm">
                <input
                  type="text"
                  @input="iptchange"
                  id="prise_username"
                  v-model="prise_username"
                  placeholder="请输入昵称"
                />
                <input
                  type="text"
                  @input="iptchangema"
                  name=""
                  id="prise_phone"
                  v-model="prise_phone"
                  placeholder="请输入手机号"
                />
                <div class="login_pccenterboxmmpro">
                  <input
                    type="text"
                    @input="iptchange"
                    name=""
                    v-model="prise_regcode"
                    id="prise_regcode"
                    placeholder="请输入验证码"
                  />
                  <input
                    class="login_pccenterboxmmprohq"
                    :class="login_pccenterboxmmprohq"
                    @click="phone_showtime(1)"
                    readonly="readonly"
                    :value="shejishim"
                    :style="login_pccenterboxmmprohqbk"
                  />
                </div>
                <div class="login_pccenterboxmmmmyc">
                  <input
                    :type="mmyctype"
                    @input="iptchange"
                    name=""
                    v-model="prise_password"
                    id="prise_password"
                    placeholder="密码最少6个字符"
                  />
                  <img
                    @click="login_pccenterboxmmmmyc(0)"
                    v-show="mmyc0"
                    src="https://task.hozoin.cn/Works/20201111/sj-5731790922.png"
                    alt=""
                  />
                  <img
                    @click="login_pccenterboxmmmmyc(1)"
                    v-show="mmyc1"
                    src="https://task.hozoin.cn/Works/20201111/sj-0148929739.png"
                    alt=""
                  />
                </div>
                <div class="login_pccenterboxmmck">
                  <el-checkbox v-model="checked"
                    >确认已阅读并同意遵守<router-link
                      target="_blank"
                      to="/help?data=24&big=58"
                      >《和作营用户协议》</router-link
                    ></el-checkbox
                  >
                </div>
                <div
                  class="login_pccenterboxmmbtn"
                  @click="user_registervue(1)"
                  :style="login_pccenterboxmmbtnbk"
                >
                  立即注册
                </div>
                <div class="login_pccenterboxmmzc">
                  <div>
                    <router-link to="/Sjlogin">已有帐号请登陆</router-link>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="企业注册" name="two">
              <div class="login_pccenterboxmm">
                <input
                  type="text"
                  @input="iptchange_qy"
                  v-model="desi_username"
                  id="desi_username"
                  placeholder="请输入公司名称"
                />
                <input
                  type="text"
                  @input="iptchange_qypon"
                  v-model="desi_phone"
                  id="desi_phone"
                  placeholder="请输入手机号"
                />
                <div class="login_pccenterboxmmpro">
                  <input
                    type="text"
                    @input="iptchange_qy"
                    v-model="desi_regcode"
                    id="desi_regcode"
                    placeholder="请输入验证码"
                  />
                  <input
                    class="login_pccenterboxmmprohq"
                    :class="login_pccenterboxmmprohq"
                    @click="phone_showtime(2)"
                    readonly="readonly"
                    :value="shejishim"
                    :style="login_pccenterboxmmprohqbk"
                  />
                </div>
                <div class="login_pccenterboxmmmmyc">
                  <input
                    :type="mmyctype"
                    id="desi_repassword"
                    placeholder="密码最少6个字符"
                    @input="iptchange_qy"
                    v-model="desi_repassword"
                  />
                  <img
                    @click="login_pccenterboxmmmmyc(0)"
                    v-show="mmyc0"
                    src="https://task.hozoin.cn/Works/20201111/sj-5731790922.png"
                    alt=""
                  />
                  <img
                    @click="login_pccenterboxmmmmyc(1)"
                    v-show="mmyc1"
                    src="https://task.hozoin.cn/Works/20201111/sj-0148929739.png"
                    alt=""
                  />
                </div>
                <div class="login_pccenterboxmmck">
                  <el-checkbox v-model="checked"
                    >确认已阅读并同意遵守<router-link to="/"
                      >《和作营用户协议》</router-link
                    ></el-checkbox
                  >
                </div>
                <div
                  class="login_pccenterboxmmbtn"
                  @click="user_registervue(2)"
                  :style="login_pccenterboxmmbtnbk"
                >
                  立即注册
                </div>
                <div class="login_pccenterboxmmzc">
                  <div>
                    <router-link to="/Sjlogin">已有帐号请登陆</router-link>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userinfo: {},
      first: "first",
      checked: false,
      mmyc0: true,
      mmyc1: false,
      mmyctype: "password",
      prise_username: "",
      prise_phone: "",
      prise_regcode: "",
      prise_password: "",
      desi_username: "",
      desi_phone: "",
      desi_regcode: "",
      desi_repassword: "",
      login_pccenterboxmmbtnbk: "background:#ccc",
      login_pccenterboxmmprohqbk: "background:#ccc",
      timer: null,
      count: 0,
      login_pccenterboxmmprohq: "",
      shejishim: "获取验证码",
      userid: "",
      userinfodata: "",
    };
  },
  methods: {
    changedl(index) {
      var that = this;
      // that.prise_username = "";
      // that.prise_phone = "";
      // that.prise_regcode = "";
      // that.prise_password = "";
      // that.desi_username = "";
      // that.desi_phone = "";
      // that.desi_regcode = "";
      // that.desi_repassword = "";
      that.login_pccenterboxmmbtnbk = "background:#ccc";
      that.login_pccenterboxmmprohqbk = "background:#ccc";
    },
    iptchange() {
      var that = this;
      if (
        that.prise_username != "" &&
        that.prise_phone != "" &&
        that.prise_regcode != "" &&
        that.prise_password != ""
      ) {
        that.login_pccenterboxmmbtnbk = "background:#fc9732";
      } else {
        that.login_pccenterboxmmbtnbk = "background:#ccc";
      }
    },
    iptchangema() {
      var that = this;
      if (that.prise_phone != "") {
        that.login_pccenterboxmmprohqbk = "background:#fc9732";
      } else if (that.prise_phone == "") {
        that.login_pccenterboxmmprohqbk = "background:#ccc";
      }
    },
    phone_showtime(id) {
      var that = this;
      if (id == 1) {
        var mobile = that.prise_phone;
      } else {
        var mobile = that.desi_phone;
      }
      // 获取验证码
      this.$axios({
        url: "/api/package/getMobileCode",
        method: "post",
        data: {
          mobile,
          get_type: "register",
        },
      }).then((res) => {
        if (res.data.code == "1") {
          this.$message({
            message: "验证码发送成功，请注意查收",
            duration: 1000,
          });

          const TIME_COUNT = 60;
          if (that.timer == null) {
            that.login_pccenterboxmmprohq = "clicknone";
            that.count = TIME_COUNT;
            that.login_pccenterboxmmprohqbk = "background:#ccc";
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= TIME_COUNT) {
                that.count--;
                that.shejishim = "" + that.count + "秒后重新获取";
              } else {
                that.login_pccenterboxmmprohqbk = "background:#fc9732";
                clearInterval(that.timer);
                that.timer = null;
                that.login_pccenterboxmmprohq = "";
                that.shejishim = "获取验证码";
              }
            }, 1000);
          }
        } else {
          this.$message({
            message: res.data.messages,
            duration: 1000,
          });
        }
      });
    },
    login_pccenterboxmmmmyc(id) {
      var that = this;
      that.mmyc0 = !that.mmyc0;
      that.mmyc1 = !that.mmyc1;
      if (id == 0) {
        that.mmyctype = "text";
      } else {
        that.mmyctype = "password";
      }
    },
    user_registervue(id) {
      var that = this;
      var reg = /^[\da-z]+$/i;
      if (id == 1) {
        var mobile = that.prise_phone;
        var groupid = id;
        var username = that.prise_username;
        var code = that.prise_regcode;
        var password = that.prise_password;
      } else {
        var mobile = that.desi_phone;
        var groupid = id;
        var username = that.desi_username;
        var code = that.desi_regcode;
        var password = that.desi_repassword;
      };
      if (reg.test(password)) {
        var b = password.split("");
        if (b.length < 6) {
          this.$message({
            message: "密码最少为6位！",
            duration: 3000,
          });
          return false;
        }
      } else {
        this.$message({
          message: "密码只能输入字母和数字！",
          duration: 3000,
        });
        return false;
      };
      
      if (that.checked == true) {
        // user_register(id);
        // 用户注册 id=1个人 id=2企业
        this.$axios({
          url: "/api/settings/registered",
          type: "json",
          method: "post",
          data: {
            mobile,
            groupid,
            username,
            code,
            password,
            userid: that.userid,
          },
        }).then((res) => {
          console.log(res);
          if (res.data.code == "1") {
            if (that.userinfodata != "") {
              //执行绑定
              this.$message({
                message: res.data.messages,
                duration: 1000,
              });

              localStorage.setItem("userinfo", that.userinfodata);
              localStorage.setItem("tokentime", Date.now());
              setTimeout(function () {
                that.$router.go(-1);
              }, 2000);
            } else {
              //执行注册
              this.$message({
                message: res.data.messages,
                duration: 1000,
              });

              var checkedIdStr = JSON.stringify(res.data.result);
              localStorage.setItem("userinfo", checkedIdStr);
              localStorage.setItem("tokentime", Date.now());
              setTimeout(function () {
                that.$router.go(-1);
              }, 2000);
            }
          } else {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });
          }
        });
      } else if (that.checked == false) {
        this.$message({
          message: "请阅读并遵守《和作营用户协议》",
          duration: 1000,
        });
      } else {
        // console.log(that.checked,"未执行")
      }
    },
    iptchange_qy() {
      var that = this;
      if (
        that.desi_username != "" &&
        that.desi_phone != "" &&
        that.desi_regcode != "" &&
        that.desi_repassword != ""
      ) {
        that.login_pccenterboxmmbtnbk = "background:#fc9732";
      } else {
        that.login_pccenterboxmmbtnbk = "background:#ccc";
      }
    },
    iptchange_qypon() {
      var that = this;
      if (that.desi_phone != "") {
        that.login_pccenterboxmmprohqbk = "background:#fc9732";
      } else if (that.desi_phone == "") {
        that.login_pccenterboxmmprohqbk = "background:#ccc";
      }
    },
  },
  created() {
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.userinfo = userinfo;
    this.userid = this.$route.query.data;
    this.prise_username = this.$route.query.name;
    this.desi_username = this.$route.query.name;
    this.userinfodata = this.$route.query.userinfo;
    if (userinfo) {
      this.$router.push({ path: "/" });
    }
  },
};
</script>
<style>
@import "../assets/css/login.css";
</style>
